<template>
  <div class="subplan-box">
    <v-row
      align="center"
      align-content="center"
      justify="center"
      class="channels-row"
    >
      <div v-if="!overloadingStatus&&channelList.length===0">You have purchased all channels through Pay as go, there are currently no channels available for subscription.</div>
      <v-col
        v-for="(item, index) in channelList"
        :key="index"
        xs="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        align-self="center"
      >
        <div
          class="image-item"
          :class="{ 'box-border-radius': item.check }"
          @click="change(item)"
        >
          <v-img :src="item.channel_photo" />
          <div v-show="item.check" class="check-icon-box">
            <v-icon left color="white"> mdi-check </v-icon>
          </div>
        </div>
        <div v-show="showPrice" class="item-price-box">
          <span class="item-origin">${{ item.origin_price/100 }}</span>
          <span class="item-discount">${{ item.discount_price/100 }}</span>
        </div>
      </v-col>
    </v-row>
    <over-lay-loading ref="overlayloading" />
  </div>
</template>

<script>
import { getChannel, getTextByCode } from '@/api/user'
import OverLayLoading from './overLayLoading'

export default {
  name: 'ChooseChannel',
  components: {
    OverLayLoading
  },
  props: {
    changeHandle: {
      type: Function,
      default: () => {}
    },
    showPrice: {
      type: Boolean,
      default: false
    },
    showTrialChannels: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true,
    loading: false,
    active: null,
    channelList: [],
    from: 'sub',
    overloadingStatus: true,
    freeTrialChannels: []
  }),
  computed: {
    userSubscribeChannels() {
      const sub = this.$store.state.user.sub_data
      if (sub) {
        return sub.map((item) => item.platform_name)
      }
      return []
    },
    payGoPlatforms() {
      const userInfo = this.$store.state.user.userInfo
      return userInfo.manual_sub_platforms.split(';')
    }
  },
  // watch: {
  //   showPrice: function(newVal, oldVal) { // watch it
  //     this.showPrice = newVal
  //   }
  // },
  created() {
    this.$nextTick(() => {
      this.get_channel()
    })
  },
  methods: {
    async get_channel() {
      this.$refs.overlayloading.controlShow(true)
      if (this.showTrialChannels) { await this.getFreeTrialChannels() }
      getChannel()
        .then(
          (res) => {
            const channels = res.data
              .filter(item => {
                if (this.showTrialChannels) {
                  return this.freeTrialChannels.findIndex(name => name === item.platform_name) > -1
                }
                return true
              })
              .filter((item) => {
                if (item.status === 2) {
                  // 暂停使用的channel
                  return this.userSubscribeChannels.concat(this.payGoPlatforms).includes(
                    item.platform_name
                  )
                }
                // // 排除通过pay as go 购买的channel, 不可以同时存在
                if (this.payGoPlatforms.find(platform_name => platform_name === item.platform_name)) {
                  return false
                }
                return item.status === 0
              })
              .map((item) => {
                item.check =
                  this.userSubscribeChannels.findIndex(
                    (s) => item.platform_name === s
                  ) > -1
                return item
              })

            console.log(this.userSubscribeChannels)

            // 默认选中第一个channel
            if (this.showTrialChannels && channels && channels.length > 0) {
              console.log('selected')
              this.change(channels[0])
            }
            this.channelList = channels
            this.changeHandle(this.from)
          },
          () => {}
        )
        .finally(() => {
          this.$refs.overlayloading.controlShow(false)
          this.overloadingStatus = false
        })
    },
    async getFreeTrialChannels() {
      const result = await getTextByCode({ 'code': 'free_trial_channels' })
      this.freeTrialChannels = result.data.value
    },
    change(sectorItem) {
      sectorItem.check = !sectorItem.check
      if (this.showTrialChannels) {
        this.channelList.forEach(item => {
          if (item.check && item.platform_name !== sectorItem.platform_name) {
            item.check = false
          }
        })
      }
      this.changeHandle(this.from)
    },
    calcPrice() {
      return this.channelList
        .map((item) => {
          return item.check ? item.discount_price : 0
        })
        .reduce((acc, val) => acc + val, 0)
    },
    calcRegular() {
      return this.channelList
        .map((item) => {
          return item.check ? item.origin_price : 0
        })
        .reduce((acc, val) => acc + val, 0)
    }
  }
}
</script>
<style scoped lang="scss">
.platform-text {
  font-size: 20px;
  font-weight: 320;
}

.text--primary {
  font-size: 18px;
}

.total-price-box {
  color: white;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  padding: 16px;
  .total-price {
    font-size: 1.8em;
    font-weight: bold;
    // padding-top: 3px;
    color: #8c9eff;
  }
}

.container .container--fluid .fill-height {
  background: white !important;
}

.image-item {
  border-radius: 9px;
  position: relative;
  height: 68px;
  width: 120px;
  .v-image {
    height: 60px;
    border-radius: 4px;
  }
  .check-icon-box {
    position: relative;
    top: -60px;
    left: -2px;
    width: 20px;
    background: #e040fb;
    border-radius: 0 0 8px 0;
  }
}

.box-border-radius {
  border: 4px solid #e040fb;
}

.button-box {
  margin-top: 10px;
  .v-btn {
    background-image: linear-gradient(45deg, #8c9eff, #e040fb) !important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.channels-row {
  text-align: center;
  text-align: -webkit-center;
  .image-item {
    text-align: left !important;
  }
  .item-price-box{
    text-align: center;
    .item-discount{
      color: #8C9EFF;
      padding-left: 5px;
      font-size: 20px;
    }
    .item-origin{
      font-size: 12px;
      color: white;
      text-decoration-line: line-through;
    }
  }
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px;
}

.tip-text {
  color: white;
  font-size: smaller;
}

.subplan-box {
  min-height: 120px;
}

</style>
